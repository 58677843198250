import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  useLocation,
  useRouteMatch,
} from 'react-router-dom/cjs/react-router-dom.min';
import { useSnackbar } from 'notistack';

import {
  selectUser,
  selectProfile,
  selectOrganization,
} from 'state/auth/selectors';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { FormControl, IconButton, Paper } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

import CustomSelect from 'components/CustomSelect';
import Watchers from 'components/Watchers';
import Comments from 'components/Comment';
import Attachment from 'components/Attachment';
import AlertDialog from 'components/AlertDialog';
import LoadingButton from 'components/LoadingButton';
import LoaderSpinner from 'components/LoaderSpinner';
import ManageDependency from 'pages/ManageDependency';
import { priority, status, event, Schema, select } from './helper';

import api from 'services/Api';
import useUpdatePriority from 'hooks/services/useUpdatePriority';
import useUpdateStatus from 'hooks/services/useUpdateStatus';
import useUpdateAssignee from 'hooks/services/useUpdateAssignee';
import useUpdateTitle from 'hooks/services/useUpdateTitle';
import useUpdateDescription from 'hooks/services/useUpdateDescription';
import useWatchers from 'hooks/services/useWathcers';
import useEntitlements from 'hooks/services/useEntitlements';
import useIsAssigned from 'hooks/services/useIsAssigned';
import useUsers from 'hooks/services/useUsers';
import useTickets from 'hooks/services/useTickets';

import {
  FLOW,
  PATH_TICKET_SERVICE,
  PATH_UPLOAD_SERVICE,
  ROUTE_ROOT,
} from '../../constants';

import styles from './styles';
import { isEmpty } from 'utils/object';

const ManageTicket = () => {
  const classes = styles();
  const { path } = useRouteMatch();
  const location = useLocation().state;
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { ticketId: id, entitlementId: entId } = useParams();
  const userId = useSelector(selectUser);
  const cachedAuth = useSelector(selectProfile);
  const organizationId = useSelector(selectOrganization);

  const [ticket, setTicket] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [watchers, setWatchers] = useState([]);
  const [ticketComments, setTicketComments] = useState([]);
  const [entitlementsArray, setEntitlementsArray] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [warningDescriptipn, setWarningDescriptipn] = useState('');
  const [files, setFiles] = useState([]);
  const [ticketData, setTicketData] = useState(location?.ticketData);
  const [title, setTitle] = useState(location?.ticketData?.title || '');
  const [watchersArr, setWatchersArr] = useState([]);
  const [priorityValue, setPriorityValue] = useState();
  const [statusValue, setStatusValue] = useState();
  const [description, setDescription] = useState(
    location?.ticketData?.summary || ''
  );
  const ticketDataValue = location?.ticketData || '';
  let [flattenedTickets, setFlattenedTickets] = useState([]);
  const [ticketEntitlementId, setTicketEntitlementId] = useState(
    location?.ticketData?.entitlementId || null
  );

  const [currentComment, setCurrentComment] = useState('');
  const selectedWatchers = [];
  const { data: myTickets } = useTickets({
    orgId: organizationId,
    userId: userId,
    entitlementId: [entId],
    ticketId: parseInt(id),
  });

  const setEnqueueSnackbar = (msg, snackerVariant) => {
    enqueueSnackbar(msg, {
      variant: snackerVariant,
      autoHideDuration: 3000,
    });
  };

  const flow = useLocation()?.pathname.includes('create')
    ? FLOW.CREATE
    : FLOW.VIEW;
  const {
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
    formState,
    reset,
  } = useForm({
    resolver: yupResolver(Schema),
    mode: 'onBlur',
    defaultValues: {
      title: undefined,
      description: undefined,
    },
  });

  const { data: activeUsers, isFetching } = useUsers({
    organizationId,
    userStatus: 'ACTIVE',
  });
  const { data: entitlements } = useEntitlements(cachedAuth);
  const { mutateAsync: assigneeUpdater } = useUpdateAssignee(ticketDataValue);
  const { mutateAsync: titleUpdater } = useUpdateTitle(ticketDataValue, title);
  const { mutateAsync: descriptionUpdater } =
    useUpdateDescription(ticketDataValue);
  const { mutateAsync: isAssigned } = useIsAssigned(ticketDataValue);
  const { data: users } = useWatchers(cachedAuth);
  const { mutateAsync: priorityUpdater } = useUpdatePriority(
    ticketDataValue,
    priorityValue
  );
  const { mutateAsync: statusUpdater } = useUpdateStatus(
    ticketDataValue,
    statusValue,
    event
  );

  const [depTickets, setDepTickets] = useState([]);
  const { data: allTicketData } = useTickets({ orgId: organizationId });

  const getCurrentCommentValue = (value) => {
    setCurrentComment(value);
  };

  useEffect(() => {
    if (allTicketData) {
      const dependencyTickets = allTicketData.pages.flatMap(
        (page) => page.tickets
      );
      setDepTickets(dependencyTickets);
    }
  }, [allTicketData]);

  useEffect(() => {
    if (!location?.ticketData && myTickets && flow === FLOW.VIEW) {
      let reducedflattenedTickets = myTickets?.pages.reduce((accum, curr) => {
        return [...accum, ...curr.tickets];
      }, []);

      if (reducedflattenedTickets) {
        setFlattenedTickets(reducedflattenedTickets);
      }
    }
  }, [myTickets]);

  flattenedTickets = depTickets;

  const ticketInfo = (ticketId) => {
    const ticket = flattenedTickets?.find(
      (ticket) => String(ticket.ticketId) === ticketId
    );
    if (ticket !== ticketData) {
      setTicketData(ticket);
    }
  };

  const flattenedUsers = activeUsers?.pages.reduce((accum, curr) => {
    return [...accum, ...curr];
  }, []);

  const usersWithName = flattenedUsers?.map((user) => {
    const namedUser = {
      value: user.email,
      name: user.firstName + ' ' + user.lastName,
      id: user.id,
    };
    return namedUser;
  }, []);

  const flattenedWatchers = users?.pages.reduce((accum, curr) => {
    return [...accum, ...curr];
  }, []);

  const flattenedEntitlements = entitlements?.pages.reduce((accum, curr) => {
    return [...accum, ...curr];
  });

  useEffect(() => {
    if (flattenedEntitlements && flattenedEntitlements.length > 0) {
      const modifyEntitlements = flattenedEntitlements.filter(
        (x) => x.entitlementStatus === 'ACTIVE'
      );

      const filteredEntitlements = modifyEntitlements.map(
        ({ name, entitlementId }) => {
          return {
            name: name,
            value: entitlementId,
          };
        }
      );

      if (modifyEntitlements.length === 1) {
        setTicketEntitlementId(modifyEntitlements[0].value);
        changeEntitlementId(modifyEntitlements[0].value);
      }
      setEntitlementsArray(filteredEntitlements);
    }
  }, [entitlements, flattenedEntitlements]);

  const { isDirty } = formState;
  const onSubmit = async (data) => {
    try {
      setIsUpdating(true);
      if (ticketData) {
        const existingTicket = {
          ...data,
        };
        const updateTicket = await api.put(
          `${PATH_TICKET_SERVICE}/v1/tickets/${ticketData.entitlementId}/${ticketData.ticketId}`,
          JSON.stringify(existingTicket)
        );

        const result = await updateTicket.json();
        setTicket([...ticket, result.data]);
      } else {
        const checkedWatchers = watchersArr.filter(
          (watcher) => watcher.isChecked === true
        );
        checkedWatchers.map((watcher) => delete watcher.isChecked);
        const uploadedFiles = [];

        for (const f of files) {
          const data = new FormData();
          data.append('file', f);
          try {
            const addFile = await api.post(
              `${PATH_UPLOAD_SERVICE}/v1/file`,
              data
            );
            const result = await addFile.json();
            const url = result.data.url;
            const file = url.substring(url.lastIndexOf('/') + 1);
            const fileArray = file.split('.');
            const fileName = fileArray[0];
            const fileType = fileArray[1];

            uploadedFiles.push({
              fileName,
              fileType,
              url,
            });
          } catch (error) {
            // Error not threated to avoid tickets not to be posted because of a S3 problem
            console.log(error);
          }
        }

        const newTicket = {
          ...data,
          watchers: checkedWatchers,
          attachments: uploadedFiles,
        };
        const addTicket = await api.post(
          `${PATH_TICKET_SERVICE}/v1/tickets`,
          JSON.stringify(newTicket)
        );
        const result = await addTicket.json();
        setTicket([...ticket, result.data]);
      }
      history.goBack(`/${path}`);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const handleOk = () => {
    history.goBack(`/${path}`);
    handleAlertClose();
  };

  const changePriority = (value) => {
    setValue('userPriority', value);
    if (ticketData) {
      setPriorityValue(value);
    }
  };
  useEffect(() => {
    const updatePriority = async () => {
      try {
        await priorityUpdater();
        setEnqueueSnackbar('Priority updated successfully', 'success');
      } catch (e) {
        setEnqueueSnackbar('Failed to update priority', 'error');
        console.log(e);
      }
    };
    if (priorityValue) {
      updatePriority();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priorityValue]);

  const changeEntitlementId = (entitlementId) => {
    setTicketEntitlementId(entitlementId);
    setValue('entitlementId', entitlementId);
  };

  const changeStatus = async (value) => {
    if (ticketData) {
      setStatusValue(value);
    }
  };

  useEffect(() => {
    const checkAssignee = {
      isAssigned: true,
    };
    const updateStatus = async () => {
      try {
        await statusUpdater();
        setEnqueueSnackbar('Status updated successfully', 'success');
        isAssigned(checkAssignee);
      } catch (e) {
        setEnqueueSnackbar('Failed to update status', 'error');
        console.log(e);
      }
    };
    if (statusValue) {
      updateStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusValue]);

  const changeAssignee = async (e) => {
    const user = usersWithName.find((user) => user.value === e.target.value);
    const assigneeDTO = { userId: user.id, email: user.value };

    try {
      await assigneeUpdater(assigneeDTO);
      setEnqueueSnackbar('Assignee updated successfully', 'success');
    } catch (e) {
      setEnqueueSnackbar('Failed to update assignee', 'error');
      console.log(e);
    }
  };

  const updateTitle = () => {
    const checkAssignee = {
      isAssigned: true,
    };
    if (ticketData && ticketData.title !== title) {
      const callTicketService = async () => {
        try {
          await titleUpdater();
          isAssigned(checkAssignee);
        } catch (e) {
          console.log(e);
        }
      };

      let debouncer = setTimeout(() => {
        callTicketService();
      }, 20);
      return () => {
        clearTimeout(debouncer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const updateDescription = async () => {
    const checkAssignee = {
      isAssigned: true,
    };
    if (ticketData && ticketData.summary !== description) {
      const callTicketService = async () => {
        const dto = { description: description };
        try {
          await descriptionUpdater(dto);
          isAssigned(checkAssignee);
        } catch (e) {
          console.log(e);
        }
      };

      let debouncer = setTimeout(() => {
        callTicketService();
      }, 40);

      return () => {
        clearTimeout(debouncer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handlePreClose = () => {
    const currentValues = getValues(['entitlementId', 'title', 'description']);
    const populatedValues = Object.values(currentValues).filter(
      (value) => value !== ''
    );
    if (
      (populatedValues.length !== 0 &&
        isDirty &&
        !isEmpty(formState.dirtyFields)) ||
      currentComment?.length > 0
    ) {
      setOpenAlert(true);
      setWarningDescriptipn(
        'The changes you made will be lost if you navigate away from this page'
      );
    } else {
      history.push(ROUTE_ROOT);
    }
  };

  const handleUpdateTicket = () => {
    try {
      updateTitle();
      updateDescription();
      setEnqueueSnackbar('Ticket updated successfully', 'success');
    } catch (error) {
      setEnqueueSnackbar('Failed to update ticket', 'error');
      console.error(error);
    }
  };

  const fetchWatchers = async () => {
    try {
      return await flattenedWatchers;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const fetchComments = async () => {
    if (ticketData) {
      try {
        const response = await api.get(
          `${PATH_TICKET_SERVICE}/v1/tickets/${ticketData?.entitlementId}/${ticketData?.ticketId}/comments`
        );
        const {
          data: { comments },
        } = await response.json();
        if (comments) {
          setTicketComments(comments);
        }
      } catch (error) {
        return Promise.reject(error);
      }
    }
  };

  useEffect(() => {
    if (id) {
      ticketInfo(id);
    }
  }, [id, ticketInfo]);

  useEffect(() => {
    Promise.all([fetchWatchers(), fetchComments()])
      .then(([watchers, comments]) => {
        if (watchers) {
          let watcherArr = watchers?.map((watcher) => {
            return {
              userName: watcher?.firstName + ' ' + watcher?.lastName,
              userId: watcher?.id,
              email: watcher?.email,
            };
          });
          setWatchers(watcherArr);
        }
        if (comments) {
          setTicketComments(comments);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, ticketData]);

  const setDisableStatus = () => {
    if (title && entitlementsArray) {
      return !!isUpdating;
    } else {
      return true;
    }
  };

  return (
    <>
      <AlertDialog
        buttonConfirmText="LEAVE"
        buttonCancelText="STAY"
        title="Warning"
        open={openAlert}
        handleClose={handleAlertClose}
        handleOk={handleOk}
      >
        {warningDescriptipn}
      </AlertDialog>
      <Grid container>
        {ticketData ? (
          <>
            <Grid container justifyContent="space-between">
              <div className={classes.headerContainer}>
                <IconButton
                  className={classes.backArrow}
                  onClick={handlePreClose}
                >
                  <ArrowBackIcon />
                </IconButton>

                <span className={classes.topicFont}>
                  {ticketData &&
                    `${ticketData['ticketDisplayId']}: ${ticketData['title']}`}
                </span>
              </div>
              <div>
                <span>
                  <Watchers
                    watchers={watchers}
                    selectedWatchers={ticketData?.watchers || []}
                    watchersArr={watchersArr}
                    setWatchersArr={setWatchersArr}
                    ticketData={ticketData || []}
                  />
                </span>
              </div>
            </Grid>
            <Grid container item>
              <Paper className={classes.paper}>
                <form className={classes.form}>
                  <Grid container>
                    <Grid
                      item
                      container
                      xs={12}
                      sm={ticketData ? 6 : 12}
                      spacing={0}
                      className={classes.leftContainer}
                      alignContent="space-between"
                    >
                      <Grid item container spacing={2}>
                        <Grid item xs={6}>
                          <p className={classes.subTopic}>
                            Products & Services
                          </p>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            style={{ background: '#FFFFFF' }}
                            disabled={!!ticketData}
                          >
                            <CustomSelect
                              id="entitlementId"
                              name="entitlementId"
                              error={errors.entitlementId}
                              register={register}
                              value={
                                ticketData
                                  ? ticketData?.entitlementId
                                  : ticketEntitlementId
                              }
                              selectItems={select(entitlementsArray || [])}
                              onChange={(e) => {
                                changeEntitlementId(e.target.value);
                              }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                          <p className={classes.subTopic}> Created By </p>
                          <TextField
                            className={`${classes.textField} ${classes.creator}`}
                            margin="dense"
                            variant="outlined"
                            disabled={!!ticketData}
                            defaultValue={ticketData?.createdBy}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid item container spacing={2}>
                        <Grid item xs={4}>
                          <p className={classes.subTopic}> Status </p>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            style={{ background: '#FFFFFF' }}
                          >
                            <CustomSelect
                              id="status"
                              name="status"
                              defaultValue={ticketData?.status}
                              register={register}
                              selectItems={select(status)}
                              onChange={(e) => changeStatus(e.target.value)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <p className={classes.subTopic}> Priority </p>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            style={{ background: '#FFFFFF' }}
                          >
                            <CustomSelect
                              id="userPriority"
                              name="userPriority"
                              defaultValue={ticketData?.userPriority}
                              register={register}
                              selectItems={select(priority)}
                              onChange={(e) => changePriority(e.target.value)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <p className={classes.subTopic}> Assignee </p>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            style={{ background: '#FFFFFF' }}
                          >
                            <CustomSelect
                              variant="filled"
                              id="assigneeId"
                              name="assigneeId"
                              defaultValue={ticketData?.assigneeEmail}
                              register={register}
                              selectItems={usersWithName || []}
                              onChange={(e) => changeAssignee(e)}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item className={classes.gridItem}>
                        <p className={classes.subTopic}> Title </p>
                        <TextField
                          className={classes.textField}
                          inputRef={register}
                          name="title"
                          placeholder="Enter Title"
                          margin="dense"
                          variant="outlined"
                          error={!!errors.title}
                          defaultValue={ticketData?.title}
                          onChange={(e) => setTitle(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item className={classes.gridItem}>
                        <p className={classes.subTopic}> Description </p>
                        <TextField
                          className={classes.textField}
                          id="description"
                          inputRef={register}
                          name="description"
                          placeholder="Enter a description of the issue you are facing"
                          margin="dense"
                          multiline
                          minRows={10}
                          variant="outlined"
                          error={!!errors.description}
                          defaultValue={ticketData?.description}
                          onChange={(e) => setDescription(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      {/* Hiding dependencies for now because it's not working */}
                      {/*ticketData && (
                      <Grid
                        item
                        className={classes.gridItem}
                        id={'AddDependency'}
                      >
                        <ManageDependency
                          ticketId={location?.ticketData?.ticketId}
                          allTicket={flattenedTickets}
                          ticketData={ticketData}
                        />
                      </Grid>
                    )*/}
                      <Grid
                        item
                        className={classes.gridItem}
                        id={'AddAttachment'}
                      >
                        <Attachment
                          passFiles={setFiles}
                          attachments={ticketData?.attachments}
                          ticketData={ticketData}
                        />
                      </Grid>
                      {ticketData && (
                        <Button
                          id={'UpdateButton'}
                          onClick={handleUpdateTicket}
                          className={classes.updateButton}
                          autoFocus
                          variant="contained"
                          disabled={
                            !formState.isDirty && isEmpty(formState.dirtyFields)
                          }
                        >
                          Update
                        </Button>
                      )}
                    </Grid>

                    {ticketData && (
                      <Grid
                        item
                        container
                        md={6}
                        xs={12}
                        className={classes.commentsContainer}
                        style={{ marginTop: '10px' }}
                      >
                        <Comments
                          ticketData={ticketData}
                          ticketComments={ticketComments}
                          xs={12}
                          getCurrentCommentValue={getCurrentCommentValue}
                        />
                      </Grid>
                    )}
                  </Grid>
                </form>
              </Paper>
            </Grid>
          </>
        ) : (
          flow === FLOW.VIEW &&
          !ticketData && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ width: '100%', height: '100%' }}
            >
              <LoaderSpinner
                type="Bars"
                color="#00BFFF"
                height={70}
                width={70}
              />
            </Grid>
          )
        )}

        {flow === FLOW.CREATE && (
          <Grid container>
            <Grid container justifyContent="space-between">
              <div className={classes.headerContainer}>
                <IconButton
                  className={classes.backArrow}
                  onClick={handlePreClose}
                >
                  <ArrowBackIcon />
                </IconButton>

                <h1 className={classes.topicFont}>{'Create New Ticket'}</h1>
              </div>
              <div>
                <span>
                  <Watchers
                    watchers={watchers}
                    selectedWatchers={selectedWatchers}
                    watchersArr={watchersArr}
                    setWatchersArr={setWatchersArr}
                    ticketData={[]}
                  />
                </span>
              </div>
            </Grid>
            <Grid container item>
              <form className={classes.form}>
                <Grid container>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    spacing={0}
                    className={classes.leftContainer}
                    alignContent="space-between"
                  >
                    <Grid item container spacing={2}>
                      <Grid item xs={6}>
                        <p className={classes.subTopic}>Products & Services</p>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          style={{ background: '#FFFFFF' }}
                        >
                          <CustomSelect
                            id="entitlementId"
                            name="entitlementId"
                            error={errors.entitlementId}
                            register={register}
                            value={ticketEntitlementId}
                            selectItems={select(entitlementsArray || [])}
                            onChange={(e) => {
                              changeEntitlementId(e.target.value);
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <p className={classes.subTopic}> Priority </p>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          style={{ background: '#FFFFFF' }}
                        >
                          <CustomSelect
                            id="userPriority"
                            name="userPriority"
                            defaultValue={`LOW`}
                            register={register}
                            selectItems={select(priority)}
                            onChange={(e) => changePriority(e.target.value)}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item className={classes.gridItem}>
                      <p className={classes.subTopic}> Title </p>
                      <TextField
                        className={classes.textField}
                        inputRef={register}
                        name="title"
                        placeholder="Enter Title"
                        margin="dense"
                        variant="outlined"
                        error={!!errors.title}
                        defaultValue={''}
                        onChange={(e) => setTitle(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item className={classes.gridItem}>
                      <p className={classes.subTopic}> Description </p>
                      <TextField
                        className={classes.textField}
                        id="description"
                        inputRef={register}
                        name="description"
                        placeholder="Enter Description"
                        margin="dense"
                        multiline
                        minRows={10}
                        variant="outlined"
                        error={!!errors.description}
                        defaultValue={''}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      item
                      className={classes.gridItem}
                      id={'AddAttachment'}
                    >
                      <Attachment passFiles={setFiles} />
                    </Grid>
                  </Grid>
                </Grid>
                <div className={classes.actionSpacing}>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginTop: '20px',
                      marginBottom: '20px',
                    }}
                  >
                    <Button
                      id={'CancelButton'}
                      variant="outlined"
                      className={classes.cancelButton}
                      onClick={handlePreClose}
                    >
                      Cancel
                    </Button>

                    <LoadingButton
                      id={'CreateButton'}
                      onClickHandler={handleSubmit(onSubmit)}
                      updatingStatus={isUpdating}
                      disableStatus={setDisableStatus()}
                    >
                      Create
                    </LoadingButton>
                  </Grid>
                </div>
              </form>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ManageTicket;
